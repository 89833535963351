import React, {useEffect, useRef, useState} from 'react';
import './App.css';
import {db} from "./firebase";
import {collection, GeoPoint, getDocs} from "firebase/firestore";
import {QuerySnapshot} from "@firebase/firestore";
import ArrayOfCoords = naver.maps.ArrayOfCoords;
import Modal from 'react-modal';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import SendIcon from '@mui/icons-material/Send';
import InstagramIcon from '@mui/icons-material/Instagram';
import PlaceIcon from '@mui/icons-material/Place';


function App() {
    const mapElement = useRef(null);
    const {naver} = window;
    // const [selectedMarker, setSelectedMarker] = useState<naver.maps.Marker | null>(null);
    const [selectedRoads, setSelectedRoads] = useState<Road | null>(null);
    const [roads, setRoads] = useState<Road[] | null>([]);
    const [notiModalIsOpen, setNotiModalIsOpen] = React.useState(true);
    const [detailModalIsOpen, setdetailModalIsOpen] = React.useState(false);
    const [customStyles, setCustomStyles] = useState({
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '80%', // 기본 너비
        },
    });

    var markers: naver.maps.Marker[] = [];
    var startEndMarkers: naver.maps.Marker[] = [];
    var polyline: naver.maps.Polyline | null = null;

    // let subtitle;

    const addRoad = (newRoad: Road) => {
        setRoads((prevRoads) => {
            return prevRoads ? [...prevRoads, newRoad] : [newRoad];
        });
    }

    function openModal() {
        // setIsOpen(true);
    }


    // function afterOpenModal() {
    //     // references are now sync'd and can be accessed.
    //     subtitle.style.color = '#f00';
    // }

    function closeModal() {
        setNotiModalIsOpen(false);
    }

    function closeDetailModal() {
        setdetailModalIsOpen(false);
    }

    const handleSendClick = () => {
        // 이메일 보내기
        window.location.href = 'mailto:sinhwan0211@gmail.com';
    };

    const handleOpenInstagram = () => {
        // 새 창에서 Instagram 페이지 열기
        window.open('https://www.instagram.com/roadhub_/', '_blank');
    };



    const getRoads = () => {
        getDb().then(result => {
            result.forEach((doc) => {
                    console.log(`doc.data() : ${roads?.length}`, doc.id, );
                    addRoad(new Road(
                        doc.id,
                        doc.data().created_at,
                        doc.data().description,
                        doc.data().address,
                        doc.data().insta_link,
                        doc.data().start_geopoint,
                        doc.data().end_geopoint,
                        doc.data().name,
                        doc.data().path,
                        doc.data().recommend,
                        doc.data().tags,
                        doc.data().updated_at,
                        doc.data().writer
                    ))
                    ;
                    // console.log(`doc.data() : ${roads?.length}`, doc.data(), );
                    // // 지도에 표시할 위치의 위도와 경도 좌표를 파라미터로 넣어줍니다.
                    // const markerPoint = doc.data().start_geopoint;
                    // console.log(markerPoint);
                    //
                    // const markerLocation = new naver.maps.LatLng(markerPoint.latitude, markerPoint.longitude);
                    // var marker = new naver.maps.Marker({
                    //     position: markerLocation,
                    //     map: map,
                    //     title: doc.data().name,
                    //     icon: {
                    //         url: `${process.env.PUBLIC_URL}/마커02.png`,
                    //         size: new naver.maps.Size(25, 25),
                    //     }
                    // });
                    // markers.push(marker);
                }
            );


        });
    }

    useEffect(() => {

        getRoads();

    }, []);
    // 모달 size 동적 변화
    useEffect(() => {
        function handleResize() {
            const newWidth = window.innerWidth >= 480 ? '40%' : '80%';
            setCustomStyles(prevStyles => ({
                ...prevStyles,
                content: {
                    ...prevStyles.content,
                    width: newWidth
                }
            }));
        }

        window.addEventListener('resize', handleResize);
        handleResize(); // 초기 렌더링에서도 실행

        return () => window.removeEventListener('resize', handleResize);
    }, []);
    useEffect(() => {
        console.log(`roads updated: ${roads ? roads.map(road => road.name).join(', ') : 'null'}`);
        const location = new naver.maps.LatLng(37.5656, 126.9769);
        const mapOptions = {
            center: location,
            zoom: 13,
            zoomControl: true,
        };
        if (!mapElement.current || !naver) return;
        const map = new naver.maps.Map(mapElement.current, mapOptions);
        map.setOptions(
            {
                scaleControl: false,
                logoControl: false,
                mapDataControl: false,
                zoomControl: false,
                mapTypeControl: false
            }
        )

        roads?.forEach((road) => {
            console.log('road', road);
            // 지도에 표시할 위치의 위도와 경도 좌표를 파라미터로 넣어줍니다.
            const markerPoint = road.start_geopoint;
            console.log(markerPoint);

            const markerLocation = new naver.maps.LatLng(markerPoint.latitude, markerPoint.longitude);
            var marker = new naver.maps.Marker({
                position: markerLocation,
                map: map,
                title: road.name,
                icon: {
                    url: `${process.env.PUBLIC_URL}/marker_base.svg`,
                    size: new naver.maps.Size(40, 50),
                }
            });
            markers.push(marker);
        })

        //
        naver.maps.Event.addListener(map, 'click', function(e) {
            if (roads) {
                showRoads();
                setSelectedRoads(null);
                startEndMarkers.forEach((marker) => {
                    marker.onRemove();
                });
                startEndMarkers = [];
                polyline?.onRemove();
                polyline = null;
            }
        });

        for (var i = 0, ii = markers.length; i < ii; i++) {
            naver.maps.Event.addListener(markers[i], 'click', getClickHandler(i, map));
        }
    }, [roads]);

    return (
        <div className={'App'}>

            <div>
                <Modal
                    isOpen={notiModalIsOpen}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <img className={'modal-logo'} src={`${process.env.PUBLIC_URL}/logo_horizontal.svg`}/>

                    <div className={'modal-content'}>
                        <p>안녕하세요, <b><em>RoadHub(로덥!) 입니다.</em></b> <br/>여러분만의 특별한 Road를 공유해 보세요. 멋진 경험을 다른 라이더와 나누고 싶다면, 저희에게 메일을 보내주세요. 🤙 <br/>여러분의 Road를 모두가 기다리고 있습니다!</p>
                        <Button variant="outlined" startIcon={<InstagramIcon />} onClick={handleOpenInstagram}>
                            Instagram
                        </Button>
                        <Button variant="contained" startIcon={<SendIcon />} onClick={handleSendClick}>
                            이메일 보내기
                        </Button>
                        <Button variant="outlined" className={'modal-ok-button'} onClick={closeModal}>확인</Button>
                    </div>


                </Modal>
            </div>

            {/*<div >*/}
            {/*    <Modal*/}
            {/*        isOpen={detailModalIsOpen}*/}
            {/*        // onAfterOpen={afterOpenModal}*/}
            {/*        onRequestClose={closeDetailModal}*/}
            {/*        style={customStyles}*/}
            {/*        contentLabel="Example Modal"*/}
            {/*    >*/}
            {/*        {selectedRoads != null &&*/}
            {/*            <div className={'text-container'}>*/}
            {/*                <h1>{selectedRoads.name}</h1>*/}
            {/*                <div>*/}
            {/*                    <PlaceIcon color="disabled" sx={{ fontSize: 14 }}/>*/}
            {/*                    <text className={'text-address'}>{selectedRoads.address}</text>*/}
            {/*                </div>*/}
            {/*                <p>{selectedRoads.description}</p>*/}

            {/*            </div>*/}
            {/*        }*/}
            {/*    </Modal>*/}
            {/*</div>*/}

            <div ref={mapElement} className={'naverMap'}/>
            <div className={'home-logo'}>
                <img src={`${process.env.PUBLIC_URL}/logo_horizontal.svg`}/>
            </div>
            {selectedRoads != null && <SelectedRoadComponent road={selectedRoads}/>}


            <div className={'send-button'}>
                <Fab size="large" color="primary" aria-label="send" onClick={handleSendClick}>
                    <SendIcon />
                </Fab>
            </div>
            {/*{selectedTitle != null && <OverlayComponent title={selectedTitle} />}*/}
            {/**/}

        </div>
    );

    function showRoads() {
        markers.forEach((marker) => {
            marker.onAdd();
        });
    }


    function hideRoads() {
        markers.forEach((marker) => {
            marker.onRemove();
        });
    }

    function getClickHandler(seq: number, map: naver.maps.Map) {
        // return function () {
        //     var marker = markers[seq];
        //     if (selectedTitle) {
        //         console.log('111',selectedTitle);
        //         setSelectedTitle(null);
        //     } else {
        //         console.log('222',marker.getTitle());
        //         setSelectedTitle(marker.getTitle());
        //     }
        //
        //     console.log(marker.getTitle(), selectedTitle);
        // }
        return function () {
            var marker = markers[seq];
            setSelectedRoads(prevRoad => {
                console.log('prevTitle', prevRoad, marker.getTitle());
                if (prevRoad && prevRoad.name === marker.getTitle()) {
                    console.log('Unsetting title', prevRoad);
                    showRoads();
                    return null;
                } else {
                    hideRoads();
                    console.log('Setting title to');
                    return roads ? roads[seq] : null;
                }
            });
            if (roads) {
                console.log('selectedRoads2', roads[seq]);
                const markerStartPoint = roads[seq].start_geopoint;
                const markerEndPoint = roads[seq].end_geopoint;

                const startPoint = new naver.maps.LatLng(markerStartPoint.latitude, markerStartPoint.longitude);
                var marker = new naver.maps.Marker({
                    position: startPoint,
                    map: map,
                    title: roads[seq].name,
                    icon: {
                        url: `${process.env.PUBLIC_URL}/start_1.svg`,
                    }
                });
                const endPoint = new naver.maps.LatLng(markerEndPoint.latitude, markerEndPoint.longitude);
                var marker2 = new naver.maps.Marker({
                    position: endPoint,
                    map: map,
                    title: roads[seq].name,
                    icon: {
                        url: `${process.env.PUBLIC_URL}/end_1.svg`,
                    }
                });
                map.panToBounds(
                    new naver.maps.LatLngBounds(startPoint, endPoint),
                    {},
                    { top: 50, right: 50, bottom: 50, left: 50 }
                );
                startEndMarkers.push(marker);
                startEndMarkers.push(marker2);
            }

            const path: ArrayOfCoords = [];
            if (roads) {
                roads[seq].path.forEach((point) => {
                    path.push(new naver.maps.LatLng(point.latitude, point.longitude));
                });
            }
            polyline =
            new naver.maps.Polyline({
                map: map,
                path: path,
                strokeColor: '#455838',
                strokeWeight: 4,
                strokeOpacity: 1
            });
        }
    }
    function SelectedRoadComponent({road}: SelectedRoadComponentProps) {
        const handleOpenDetail = () => {
            // setdetailModalIsOpen(true);
            if (road.insta_link != null && road.insta_link.length > 0) {
                window.open(road.insta_link, '_blank');
            }

        }

        return (
            <div className={'container'} onClick={handleOpenDetail}>
                <img src={`https://firebasestorage.googleapis.com/v0/b/roadup-53516.appspot.com/o/production%2Fcourse%2F${road.id}%2Fthumbnail.jpg?alt=media`}/>
                <div className={'text-container'}>
                    <h1>{road.name}</h1>
                    <div>
                        <PlaceIcon color="disabled" sx={{ fontSize: 14 }}/>
                        <text className={'text-address'}>{road.address}</text>
                    </div>
                    <h2>{road.description}</h2>
                    <h3>자세히보기</h3>
                </div>
            </div>

        );
    }
}




interface SelectedRoadComponentProps {
    road: Road;
}

class Road {
    id: string;
    created_at: string;
    description: string;
    address: string;
    insta_link: string;
    start_geopoint: GeoPoint
    end_geopoint: GeoPoint;
    name: string;
    path: GeoPoint[];
    recommend: number;
    tags: string[];
    updated_at: string;
    writer: string;

    constructor(
        id: string
        , created_at: string
        , description: string
        , address: string
        , insta_link: string
        , start_geopoint: GeoPoint
        , end_geopoint: GeoPoint
        , name: string
        , path: GeoPoint[]
        , recommend: number
        , tags: string[]
        , updated_at: string
        , writer: string
    ) {
        this.id = id;
        this.created_at = created_at;
        this.description = description;
        this.address = address;
        this.insta_link = insta_link;
        this.start_geopoint = start_geopoint;
        this.end_geopoint = end_geopoint;
        this.name = name;
        this.path = path;
        this.recommend = recommend;
        this.tags = tags;
        this.updated_at = updated_at;
        this.writer = writer;
    }
}


async function getDb(): Promise<QuerySnapshot> {
    console.log('getDb');
    return await getDocs(collection(db, 'production_courses'));
    // querySnapshot.forEach((doc) => {
    //     // doc.data() is never undefined for query doc snapshots
    //     console.log(querySnapshot.size, doc.id, " => ", doc.data());
    // });
}

export default App;
