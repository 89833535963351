// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore} from "firebase/firestore";
// const { initializeApp, applicationDefault, cert } = require('firebase-admin/app');
// const { getFirestore, Timestamp, FieldValue, Filter } = require('firebase-admin/firestore');

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBc6-ixcOIQSMXL_NUYlzz5bYVhFFq_4ZM",
    authDomain: "roadup-53516.firebaseapp.com",
    projectId: "roadup-53516",
    storageBucket: "roadup-53516.appspot.com",
    messagingSenderId: "922034632272",
    appId: "1:922034632272:web:8f8278a3f32c032f548355",
    measurementId: "G-DXQP4W4SQ1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);
export const db = getFirestore(app);
